import Grid from '@mui/material/Grid2';
import {Trans} from 'react-i18next';
import React from 'react';

export function NotFound() {
    return (
        <Grid container spacing={4} padding={4}>
            <h1 data-testid="not-found">
                <Trans i18nKey="pageNotFound"></Trans>
            </h1>

        </Grid>
    );
}