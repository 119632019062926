import React, {useState} from 'react';
import {Card, CardContent, Tabs, Tab} from '@mui/material';
import CardHeading from '../../shared/CardHeading';
import {TabPanel} from '../../shared/TabPanel';
import {t} from 'i18next';
import {MoodLineChart} from './moodChart/MoodLineChart';
import {WorkPlaceBarChart} from './workPlaceChart/WorkPlaceBarChart';

export function EvaluationCard() {

    const [tab, setTab] = useState(0);

    const handleChangeTab = (event: React.SyntheticEvent, newTabValue: number) => {
        setTab(newTabValue);
    };

    return (
        <Card sx={{minWidth: 275, mt: 3}}>
            <CardContent>
                <CardHeading dataTestId="evaluation-card-title" i18nKeyTitle="evaluationCard.title"/>
                <Tabs value={tab} onChange={handleChangeTab}>
                    <Tab label={t('evaluationCard.mood')} data-testid="mood-tab"/>
                    <Tab label={t('evaluationCard.workPlace')} data-testid="workplace-tab"/>
                </Tabs>

                <TabPanel value={tab} index={0}>
                    <MoodLineChart/>
                </TabPanel>

                <TabPanel value={tab} index={1}>
                    <WorkPlaceBarChart/>
                </TabPanel>
            </CardContent>
        </Card>
    );
}