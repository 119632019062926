import {moodIcons} from '../../../../model/Models';
import React from 'react';
import {Typography} from '@mui/material';
import {NameType, ValueType} from 'recharts/types/component/DefaultTooltipContent';
import {TooltipProps} from 'recharts';


export const MoodChartToolTip = ({active, payload}: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload[0].payload.avgMood !== 0) {
        const moodIcon = moodIcons[payload[0].payload.avgMood.toFixed(0)];
        return (
            <div className="chartToolTip moodCharToolTip">
                <Typography data-testid="tooltip-date-label">{payload[0].payload.date}</Typography>
                <img  data-testid="tooltip-mood-icon"
                      src={`/icons/${moodIcon}`} alt={moodIcon}
                     style={{width: '25px', height: '25px'}}/>
            </div>);
    }
    return null;
};