import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import React from 'react';
import {WorkPlaceTooltip} from './WorkPlaceTooltip';
import {Box} from '@mui/material';
import {WorkPlaceCountPerDay} from '../../../../model/Models';

export function WorkPlaceBarChart() {

    const barChartData: WorkPlaceCountPerDay[] = [
        {date: '01.11', HOME_OFFICE: 2, CLIENT: 3, ONSITE: 7},
        {date: '02.11', HOME_OFFICE: 3, CLIENT: 2, ONSITE: 3},
        {date: '03.11', HOME_OFFICE: 4, CLIENT: 4, ONSITE: 1},
        {date: '04.11', HOME_OFFICE: 1, CLIENT: 5, ONSITE: 5},
        {date: '05.11', HOME_OFFICE: 5, CLIENT: 1, ONSITE: 3}
    ];

    return (
        <Box data-testid="workplace-chart">
            <ResponsiveContainer width="100%" height={300} style={{marginTop: 10}}>
                <BarChart data={barChartData}>
                    <XAxis dataKey="date"/>
                    <YAxis/>
                    <Tooltip cursor={{fill: '#ffffff'}} content={WorkPlaceTooltip}/>
                    <Bar isAnimationActive={false} dataKey="ONSITE" stackId="workPlace" fill="#73BBA3"/>
                    <Bar isAnimationActive={false} dataKey="HOME_OFFICE" stackId="workPlace" fill="#8884d8"/>
                    <Bar isAnimationActive={false} dataKey="CLIENT" stackId="workPlace" fill="#BBE9FF"/>
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
}