import './App.css';
import * as React from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Dashboard from './views/Dashboard';
import Team from './views/Team';
import * as genkiTheme from './styles/GenkiStyle';
import {Login} from './views/Login';
import {AdminRoute, PrivateRoute} from './components/shared/PrivateRoute';
import ErrorSnackBar from './components/shared/ErrorSnackBar';
import {NotFound} from './views/NotFound';
import PasswordRecovery from './views/PasswordRecovery';
import MainNavigation from './components/shared/MainNavigation';
import Administration from './views/Administration';

function App() {
    return (
        <ThemeProvider theme={genkiTheme.theme}>
            <CssBaseline/>
            <BrowserRouter future={{
                v7_startTransition: true,
                v7_relativeSplatPath: true,
            }}>
                <MainNavigation/>
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/forgot-password" element={<PasswordRecovery/>}/>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/" element={<Dashboard/>}/>
                        <Route path="team" element={<Team/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                    <Route element={<AdminRoute/>}>
                        <Route path="/administration" element={<Administration/>}/>
                    </Route>

                </Routes>
            </BrowserRouter>
            <ErrorSnackBar></ErrorSnackBar>
        </ThemeProvider>

    );
}

export default App;
