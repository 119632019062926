import api from '../config/AxiosConfig';
import {UserService} from './UserService';
import {AvgMoodPerDay} from '../model/Models';

export class CheckInService {

    static async updateMood(mood: number | null, workPlace: string | null): Promise<void> {
        let response = await api.post('/check-in', {mood: mood, workPlace: workPlace});
        return response.data;
    }

    static async getAllMoods(): Promise<Record<number, number>> {
        let response = await api.get('/check-in/mood/' + UserService.getCurrentUser()?.companyId);
        return response.data;
    }

    static async getAllWorkSettings(): Promise<Record<string, number>> {
        let response = await api.get('/check-in/work-setting/' + UserService.getCurrentUser()?.companyId);
        return response.data;
    }

    static async getAvgMoodPerDay(): Promise<AvgMoodPerDay[]> {
        let response = await api.get('/check-in/avgMoodPerDay/' + UserService.getCurrentUser()?.companyId);
        return response.data;
    }

}
