import {Box, Typography} from '@mui/material';
import React from 'react';
import {TooltipProps} from 'recharts';
import {NameType, ValueType} from 'recharts/types/component/DefaultTooltipContent';
import {t} from 'i18next';


export const WorkPlaceTooltip = ({active, payload}: TooltipProps<ValueType, NameType>) => {
    if (active && payload) {
        let counter = 0;
        return (
            <div className="chartToolTip workPlace">
                <Typography data-testid="tooltip-workplace-date">{payload[0].payload.date}</Typography>
                {payload.map((entry) => {
                    const uniqueKey = `workplace-${counter++}`;
                    return (
                        <Box key={uniqueKey}>
                            <Typography sx={{color: entry.color}} display={'flex'} data-testid={"tooltip-workplace-value-" + entry.name}>
                                {t('workSettings.workAnswers.' + entry.name)}: {entry.value}
                            </Typography>
                        </Box>
                    );
                })
                }
            </div>);
    }
    return null;
};