import {Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {MoodChartToolTip} from './MoodChartTooltip';
import React, {useEffect, useState} from 'react';
import {AvgMoodPerDay, moodIcons, MoodLineChartYaxis} from '../../../../model/Models';
import {Box} from '@mui/material';
import {CheckInService} from '../../../../services/CheckInService';


export function MoodLineChart() {

    const [avgMoodPerDay, setAvgMoodPerDay] = useState<AvgMoodPerDay[]>();

    const formatDate = (result: AvgMoodPerDay[]) => {
        for (let avgMoodPerDay of result) {
            const date = new Date(avgMoodPerDay.date);
            avgMoodPerDay.date = date.getDate() + '.' + (date.getMonth() + 1) + '.';
        }
    };

    useEffect(() => {
        CheckInService.getAvgMoodPerDay().then(result => {
            formatDate(result);
            setAvgMoodPerDay(result);
        });
    }, []);


    function getYAxisTicks(x: number, y: number, payload: MoodLineChartYaxis) {
        if (payload.value === 0) {
            return <g></g>;
        }
        return <g transform={`translate(${x},${y - 15})`} data-testid={'mood-icon-' + payload.value}>
            <image href={`/icons/${moodIcons[payload.value]}`} width="25px" height="25px"/>
        </g>;
    }

    return (
        <Box data-testid="mood-chart">
            {avgMoodPerDay ?
                <ResponsiveContainer width="100%" height={300} style={{marginTop: 10}} className="avg-mood-chart">
                    <LineChart data={avgMoodPerDay}>
                        <Line type="monotone" dataKey="avgMood" stroke="#8884d8" strokeWidth={2}
                              isAnimationActive={false}/>
                        <XAxis dataKey="date"/>
                        <Tooltip content={MoodChartToolTip}/>
                        <YAxis tick={({x, y, payload}) => getYAxisTicks(x, y, payload)}
                               ticks={[0, 1, 2, 3, 4, 5]}
                               tickCount={6} tickMargin={50} domain={[1, 'auto']}/>
                    </LineChart>
                </ResponsiveContainer>
                : null}
        </Box>
    );

}