import React from 'react';
import Grid from '@mui/material/Grid2';
import MoodCard from '../components/dashboard/MoodCard';
import WorkSettingCard from '../components/dashboard/WorkSettingCard';
import ThoughtsPool from '../components/dashboard/ThoughtsPool';
import {EvaluationCard} from '../components/dashboard/evaluationCard/EvaluationCard';

export default function Dashboard() {
    return (
        <Grid container spacing={4} padding={2}>
            <Grid size={{xs: 12, lg: 6, md: 6}}>
                <MoodCard/>
                <WorkSettingCard/>
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
                <ThoughtsPool/>
                <EvaluationCard />
            </Grid>
        </Grid>
    );
}